const uri = 'https://f067y5bgql.execute-api.us-east-1.amazonaws.com/dev/gsdb/'
export default {
  reqHeaders: {
    'Content-Type': 'application/json',
  },
  // GoogleSheets DB API
  /**
    POST - https://f067y5bgql.execute-api.us-east-1.amazonaws.com/dev/gsdb/newSheet
    POST - https://f067y5bgql.execute-api.us-east-1.amazonaws.com/dev/gsdb/newRow/{sheetTitle}
    GET - https://f067y5bgql.execute-api.us-east-1.amazonaws.com/dev/gsdb/getRow/{sheetTitle}
    PUT - https://f067y5bgql.execute-api.us-east-1.amazonaws.com/dev/gsdb/updateRow/{sheetTitle}
    DELETE - https://f067y5bgql.execute-api.us-east-1.amazonaws.com/dev/gsdb/deleteRow/{sheetTitle}
  */
  gsdb: {
    newSheet: {
      endpoint: `${uri}newSheet`,
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
    newRow: {
      endpoint: `${uri}newRow/{sheetTitle}`,
      fetchParams: { mode: 'no-cors', method: 'POST' },
    },
    getRow: {
      endpoint: `${uri}getRow/{sheetTitle}`,
      fetchParams: { mode: 'no-cors', method: 'GET' },
    },
    updateRow: {
      endpoint: `${uri}updateRow/{sheetTitle}`,
      fetchParams: { mode: 'no-cors', method: 'PUT' },
    },
    deleteRow: {
      endpoint: `${uri}deleteRow/{sheetTitle}`,
      fetchParams: { mode: 'no-cors', method: 'DELETE' },
    },
  },
}
