/**
 * That's So Fetch API Module
 *
 * This acts as the main entry point for the UI to interact with custom APIs.
 * It includes an API REQUEST MAP and functions to simplify
 * building, executing, and returning values from HTTPS API requests
 *
 */
import fetch from 'cross-fetch'
import queryString from 'query-string'

// Builds fetch parameters
const buildFetch = (
  api,
  apiName,
  apiEndpoint,
  params = null,
  query = null,
  body = null
) => {
  let uri = api[apiName][apiEndpoint].endpoint
  // Adds parameters using regex search and replace as needed
  // Currently only works with required parameters for now...
  // console.log(params)
  if (params) {
    const paramKeys = Object.keys(params)
    paramKeys.map(param => {
      return (uri = uri.replace(new RegExp('({' + param + '})'), params[param]))
    })
  }
  // Adds query parameters to uri
  if (query) {
    const qs = queryString.stringify(query)
    uri += '?' + qs
  }
  // Build fetch options and add body
  let options = api[apiName][apiEndpoint].fetchParams
  options.header = api.reqHeaders
  if (body) options.body = JSON.stringify(body)

  // console.log({ uri, options })
  return { uri, options }
}

// Generic fetch
const doFetch = async (
  apiMap,
  apiName,
  apiEndpoint,
  params = null,
  query = null,
  body = null,
  cb = null,
  errCb = null
) => {
  // Generic debug entry point message
  console.log('Executing ' + apiEndpoint + '...')
  const fetchParams = buildFetch(
    apiMap,
    apiName,
    apiEndpoint,
    params,
    query,
    body
  )
  // console.log(fetchParams)
  return fetch(fetchParams.uri, fetchParams.options)
    .then(async res => {
      const epData = await res.json()
      return epData.message
    })
    .catch(error => {
      // Generic error message
      // alert(api.apiName.apiEndpoint.defaultErr +': '+ error)        // Browser target only
      console.log(apiEndpoint + ' error: ' + error) // Node target only
    })
}

export { doFetch as tsFetch }
