/**
 * Sign Up API Module
 *
 */
import { tsFetch } from 'apis/TSFetch'
import gsdbAPI from 'apis/GSDB/APIMap'
import GatsbyConfig from 'root/gatsby-config'

export const signUp = async (valid, email, list) => {
  if (valid) {
    // Fetch docUrl from siteMetadata
    console.log({ valid, email, list})
    const { docUrl } = GatsbyConfig.siteMetadata.gsdb
    await tsFetch(
      gsdbAPI,
      'gsdb',
      'newRow',
      // { sheetTitle: 'gbt-signup' },
      { sheetTitle: list },
      null,
      {
        docUrl,
        data: {
          created_ts: new Date().getTime(),
          created_date: new Date(),
          email,
          unsubscribed: 0,
        },
      },
      null,
      null
    )
    success(email)
    // console.log(`Signing up ${email}...`)
  }
}

// Drops me a simple message
export const messageDrop = async (msg, list) => {
  if (msg.isValid) {
    // Fetch docUrl from siteMetadata
    const { email, message, isValid } = msg
    console.log({ email, message, isValid, list})
    const { docUrl } = GatsbyConfig.siteMetadata.gsdb
    await tsFetch(
      gsdbAPI,
      'gsdb',
      'newRow',
      { sheetTitle: list },
      null,
      {
        docUrl,
        data: {
          created_ts: new Date().getTime(),
          created_date: new Date(),
          email,
          message,
        },
      },
      null,
      null
    )
    success(email)
    // console.log(`Signing up ${email}...`)
  }
}


const success = email => {
  window.alert(`Thanks ${email} you're awesome!`)
}